.image-card {
    max-width: 100%;
}

.image-card img {
    max-height: 50vh;
    max-width: 100%;
}

.image-card iframe {
    width: 500px;
    height: 50vh;
}

.image-card h1 {
    font-size: large;
    margin: .5rem 0;
}

.image-card p {
    margin: .5rem 0;
}

@media (max-width: 1400px) {
    .image-card iframe {
        width: 440px;
    }
}

@media (max-width: 1200px) {
    .image-card iframe {
        width: 360px;
    }
}

@media (max-width: 1000px) {
    .image-card iframe {
        width: 260px;
    }
}

@media (max-width: 768px) {
    .image-card img {
        max-height: 25vh;
    }

    .image-card iframe {
        width: 450px;
    }

    .image-card h1 {
        font-size: medium;
    }

    .image-card p {
        font-size: small;
    }
}

.subreddit-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subreddit-item {
    display: flex;
    justify-content: space-between;
    width: 200px;
}

.image-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
}

.top-image {
    max-width: 100%;
    height: auto;
    max-height: 30vh;
}